
import React from 'react';

const SimpleMarkerMap = () => {

  return (
    <div
      style={{ height: '70vh' }}
    >
      <div className=' mb-5 mt-5 justify-content-center align-items-center flex-row d-flex text-center'>
        <img src='assets/img/svg/location.svg' alt='location' style={{ width: '2em' }} className='mr-3' />
        <h2>Location</h2>
      </div>
      
      <iframe
        src={`https://storage.googleapis.com/maps-solutions-${process.env.REACT_APP_MAPS_API_KEY}/locator-plus/f7b3/locator-plus.html`}
        className='h-100 w-100 border rounded border-primary'
        loading="lazy"
        title="Locator Plus Map"
      ></iframe>

    </div>

  );
};

export default SimpleMarkerMap;

